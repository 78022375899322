import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import VConsole from 'vconsole';
import * as VueGoogleMaps from 'vue2-google-maps';
import { Button, Icon, Toast, Dialog, Field, Overlay, Picker, Popup } from 'vant';

Vue.use(Button)
  .use(Toast)
  .use(Dialog)
  .use(Field)
  .use(Button)
  .use(Icon)
  .use(Overlay)
  .use(Picker)
  .use(Popup)

Vue.config.productionTip = false;

// const googleKey = '';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDGBSo5jncFQQ2_yJcJKtEVegkAIM8aH9w',
    libraries: 'places',
  },
  // 地图的默认配置
  installComponents: true,
  autobindAllEvents: false,
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// 判断是否是pc设备
// const isPc = () => {
//   const userAgentInfo = navigator.userAgent;
//   const Agents = ["Android", "iPhone",
//       "SymbianOS", "Windows Phone",
//       "iPad", "iPod"];
//   let flag = true;
//   for (let v = 0; v < Agents.length; v++) {
//       if (userAgentInfo.indexOf(Agents[v]) > 0) {
//           flag = false;
//           break;
//       }
//   }
//   return flag;
// }

//如果不是生产环境并且不是pc设备那么就显示调试
if (process.env.NODE_ENV === "development" && screen.width > 800) {
  const vConsole = new VConsole();
  console.log('vConsole :>>', vConsole)
}
