import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n'


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home.vue'),
    meta: {
      title: i18n.t('message.geofencing'),
    },
  },
  {
    path: '/safeArea',
    name: 'safeArea',
    component: () => import('@/views/safeArea.vue'),
    meta: {
      title: i18n.t('message.geofencing'),
    },
  },

  {
    path: '/selectAddr',
    name: 'selectAddr',
    component: () => import('@/views/selectAddr.vue'),
    meta: {
      title: '选择安全地址',
    },
  },
  {
    path: '/gooMap',
    name: 'gooMap',
    component: () => import('@/views/gooMap.vue'),
    meta: {
      title: 'google map',
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.matched[0].meta.title;
  next();
});

export default router;
