import Vue from 'vue'
import VueI18n from 'vue-i18n'
import message from './msaage'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en', // 默认语言
  messages: message, // 语言消息对象
});

export default i18n