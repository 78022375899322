export default {
  zh: {
    message: {
      geofencing: '地理围栏',
      emergency_contect: '紧急联系人',
      cancel: '取消',
      done: '完成',
      geographic_fence_radius: '地理围栏半径(m)',
      geographic_center_tip: '将此地点设置为地理围栏中心?',
      search_location: '搜索地点',
      device_settings: '设备设置',
      radius_m: '半径/m',
      set_the_location: '设置地点',
      no_less_meters: '安全距离不低于100米',
      enter_name_addr: '请输入地址名称',
      set_succe: '设置成功',
      search_input_loc: '搜索框输入地点',
      fence_not_kilo: '围栏不应超过5公里',
      enter_safe_dis: '请输入安全距离',
      emergency_person: '紧急联系人姓名',
      emergency_number: '紧急联系电话',
      enter_phone_number: '请输入电话号码',
      enter_contact_name: '请输入联系人姓名',
      enter_correct_number: '请输入正确的电话号码',
      shall_not_exceed: '围栏半径不能超过5000m',
      fence_loc: '围栏定位',
      modify: '修改',
      add: '添加',
      fence_radius: '围栏半径',
      emer_contact: '紧急联系人',
      loading: '加载中...',
      location: '位置',
      no_support: '当前设备不支持此功能！',
    },
  },
  en: {
    message: {
      geofencing: 'Geofencing',
      emergency_contect: 'Emergency contact',
      cancel: 'Cancel',
      done: 'Done',
      geographic_fence_radius: 'Geographic fence radius(m)',
      geographic_center_tip:
        'Do you want to set this place as a geofencing center?',
      search_location: 'Search the Location',
      device_settings: 'Device settings',
      radius_m: 'Radius/m',
      set_the_location: 'set the location',
      no_less_meters: 'Security distance shall not be less than 100 meters',
      enter_name_addr: 'Please enter the name of the address',
      set_succe: 'Set successfully',
      search_input_loc: 'Search box input location',
      fence_not_kilo: 'The fence should not exceed 5 kilometers',
      enter_safe_dis: 'Please enter the safety distance',
      emergency_person: 'Enter contact person',
      emergency_number: 'Enter phone number',
      enter_phone_number: 'Please enter the phone number',
      enter_contact_name: 'Please enter the contact name',
      enter_correct_number: 'Please enter the correct phone number',
      shall_not_exceed: 'fence shall not exceed 5000m',
      fence_loc: 'Fence loc',
      modify: 'Modify',
      add: 'Add',
      fence_radius: 'Fence radius',
      emer_contact: 'Emer contact',
      loading: 'Loading...',
      location: 'Location',
      no_support: 'This feature is not supported on this device!',
    },
  },
  ko: {
    message: {
      geofencing: '지리적 울타리',
      emergency_contect: '긴급 연락처',
      cancel: '취소',
      done: '완료',
      geographic_fence_radius: '지리적 울타리 반경(m)',
      geographic_center_tip: '이 곳을 지리적 울타리 중심으로 설정하시겠습니까?',
      search_location: '검색 위치',
      device_settings: '장치 설정',
      radius_m: '반경/m',
      set_the_location: '위치 설정',
      no_less_meters: '보안 거리 100미터 이상',
      enter_name_addr: '주소 이름 입력',
      set_succe: '설정 완료',
      search_input_loc: '검색 창 입력 위치',
      fence_not_kilo: '울타리 5킬로미터 초과 안 됩니다.',
      enter_safe_dis: '보안 거리 입력',
      emergency_person: '긴급 연락처 이름',
      emergency_number: '긴급 연락 번호',
      enter_phone_number: '전화 번호 입력',
      enter_contact_name: '연락처 이름 입력',
      enter_correct_number: '올바른 전화 번호 입력',
      shall_not_exceed: '囲欄の半径は5000mを超えることができません',
      fence_loc: '울타리 위치 ',
      modify: '수정',
      add: '추가',
      fence_radius: '울타리 반경.',
      emer_contact: '긴급 연락처',
      loading: '로딩중...',
      location: '위치',
      no_support: '이 기능은 이 기기에서 지원되지 않습니다!',
    },
  },
  fr: {
    message: {
      geofencing: 'Geofence',
      emergency_contect: 'Contact d’urgence',
      cancel: 'Annuler',
      done: 'Terminé',
      geographic_fence_radius: 'Rayon de la geofence(m)',
      geographic_center_tip: 'Placer cet endroit comme centre de la geofence?',
      search_location: 'Rechercher un lieu',
      device_settings: 'Paramètres du dispositif',
      radius_m: 'Rayon/m',
      set_the_location: 'Définir l’emplacement',
      no_less_meters: 'Distance sécurisée de 100 mètres minimum',
      enter_name_addr: 'Saisir le nom de l’adresse',
      set_succe: 'Paramètres sauvegardés avec succès',
      search_input_loc: 'Saisir l’emplacement dans la boîte de recherche',
      fence_not_kilo: 'La clôture ne doit pas dépasser 5 kilomètres.',
      enter_safe_dis: 'Saisir la distance sécurisée',
      emergency_person: 'Nom du contact d’urgence',
      emergency_number: 'Numéro de contact d’urgence',
      enter_phone_number: 'Saisir le numéro de téléphone',
      enter_contact_name: 'Saisir le nom du contact',
      enter_correct_number: 'Saisir le numéro de téléphone correct',
      shall_not_exceed: 'Le rayon du pourtour ne doit pas dépasser 5000m',
      fence_loc: 'Clôture positionnée',
      modify: 'modifier',
      add: 'ajouter',
      fence_radius: 'rayon de clôture',
      emer_contact: 'Contact d\'urgence',
      loading: 'Chargement en cours...',
      location: 'Localisation',
      no_support: 'Cette fonction n\'est pas prise en charge sur cet appareil!',
    },
  },
  de: {
    message: {
      geofencing: 'Geofencing',
      emergency_contect: 'Notfallkontakt',
      cancel: 'Abbrechen',
      done: 'Fertig',
      geographic_fence_radius: 'Geofencedurchmesser(m)',
      geographic_center_tip: 'Diesen Ort zum Geofenzeenterrain?',
      search_location: 'Suchen Sie einen Ort',
      device_settings: 'Geräteeinstellungen',
      radius_m: 'Radius/m',
      set_the_location: 'Setzen Sie den Ort',
      no_less_meters: 'Sicherheit Abstand mindestens 100 Meter',
      enter_name_addr: 'Bitte geben Sie den Ortsnamen ein',
      set_succe: 'Einstellungen erfolgreich gespeichert',
      search_input_loc: 'Suchfeld eingeben Ort',
      fence_not_kilo: 'Die Grenze sollte nicht über 5 Kilometer liegen.',
      enter_safe_dis: 'Bitte geben Sie den sicheren Abstand ein',
      emergency_person: 'Notfall Kontaktperson',
      emergency_number: 'Notfall Kontakt Nummer',
      enter_phone_number: 'Bitte geben Sie die Telefonnummer ein',
      enter_contact_name: 'Bitte geben Sie den Kontaktpersonen Namen ein',
      enter_correct_number: 'Bitte geben Sie die richtige Telefonnummer ein.',
      shall_not_exceed: 'Der Radius des Zauns darf nicht über 5000m liegen',
      fence_loc: 'Zaun-Position',
      modify: 'Ändern',
      add: 'Hinzufügen',
      fence_radius: 'Zaun-Radius',
      emer_contact: 'Notfall kontakt',
      loading: 'Wird geladen...',
      location: 'Standort',
      no_support: 'Diese Funktion wird auf diesem Gerät nicht unterstützt!',
    },
  },
  es: {
    message: {
      geofencing: 'Geofenje',
      emergency_contect: 'Contacto de emergencia',
      cancel: 'Cancelar',
      done: 'Hecho',
      geographic_fence_radius: 'Radio del geofenje(m)',
      geographic_center_tip:
        '¿Quieres establecer este lugar como centro del geofenje?',
      search_location: 'Buscar lugar',
      device_settings: 'Configuración del dispositivo',
      radius_m: 'Radio/m',
      set_the_location: 'Establecer lugar',
      no_less_meters: 'Distancia segura de 100 metros mínimo',
      enter_name_addr: 'Ingresar nombre de la dirección',
      set_succe: 'Configuración guardada con éxito',
      search_input_loc: 'Ingresar lugar en el cuadro de búsqueda',
      fence_not_kilo: 'La cerca no debe exceder los 5 kilómetros.',
      enter_safe_dis: 'Ingresar distancia segura',
      emergency_person: 'Nombre del contacto de emergencia',
      emergency_number: 'Número de contacto de emergencia',
      enter_phone_number: 'Ingresar número telefónico',
      enter_contact_name: 'Ingresar nombre del contacto',
      enter_correct_number: 'Ingresar número telefónico correcto',
      shall_not_exceed: 'El radio de la cerca no puede ser mayor de 5000m',
      fence_loc: 'Ullera posición',
      modify: 'modificar',
      add: 'agregar',
      fence_radius: 'radio de cerca',
      emer_contact: 'Contacto de emer',
      loading: 'Cargando...',
      location: 'Ubicación',
      no_support: 'This feature is not supported on this device!',
    },
  },
};
//       <p>{{ $t('message.emergency_contect') }}</p> <!-- 使用 $t 方法获取翻译内容 -->
// i18n.locale = 'zh'; /
